.productTools{
    /*position: absolute;*/
    transform: translateY(50%);
}

.garbage {
    position: absolute;
    transform: translateY(-100%);
}

.select__menu {
    color: #000000 !important;
}
