body {
    min-height: 100vh;
}

.pointer {
    cursor: pointer;
}

.player video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

a, a:hover{
    text-decoration: none;
    color: inherit;
}

.select__menu {
    color: #000000 !important;
}

.MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid rgba(48, 63, 159, 0) !important;
    pointer-events: none;
}

.css-yk16xz-control {
    height: 48px !important;
    border-radius: 0 !important;
    width: 100%
}

.css-2b097c-container {
    height: 48px !important;
}

.css-1pahdxg-control {
    height: 100% !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    border-color: rgba(28, 53, 94, 0) !important;
    width: 100%
}

.css-1pahdxg-control:hover {
    border-color: rgba(28, 53, 94, 0) !important;
}